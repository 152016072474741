<template>
  <div v-if="!sized" class="loginView"></div>
  <div class="loginView">
    <div class="login-form mt-1">
      <div class="section">
        <img
          src="img/logo.png"
          alt="icon"
          class="form-image"
          style="margin-top: 50px"
        />
      </div>
      <div class="section mt-1">
        <h1>Log In</h1>
        <h4>Registration available at https://ocean</h4>
      </div>
      <div class="section mt-1 mb-5">
        <form action="app-pages.html">
          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="email"
                class="form-control"
                id="email1"
                placeholder="Email address"
                v-model="email"
              />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
              <p v-if="emailError" id="emailMessage" class="text-danger">
                {{ emailError }}
              </p>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="password"
                class="form-control"
                id="password1"
                placeholder="Password"
                autocomplete="off"
                v-model="password"
              />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                class="form-control"
                id="key"
                placeholder="Key"
                autocomplete="off"
                v-model="key"
              />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div>

          <div class="form-links mt-2">
            <div>
              <a href="javascript:void(0)" @click="goToRegister"
                >Register Now</a
              >
            </div>
          </div>

          <!--Settings-->
          <div class="form-group boxed" style="margin-top: 30px">
            <button
              type="button"
              class="btn btn-primary btn-block btn-lg btn-ocean-action"
              @click="login"
            >
              Log in
            </button>
          </div>
        </form>
      </div>

      <div class="section mt-1">
        <h1>Disclaimer</h1>
        <h4>
          This is NOT a navigation app and is for informational purposes only
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import db from "@/db";
import CryptoJS from "crypto-js";

export default {
  data() {
    return {
      email: null,
      password: null, // Added to manage the password input similarly, if needed
      emailError: "",
      sized: false,
      key: null,
    };
  },
  async created() {
    try {
      if (!db.isOpen()) {
        await db.open(); // Ensure the database is open before trying to fetch settings
      }

      const id = 1; // Fixed ID for the settings entry
      const settings = await db.Settings.get(id);

      if (settings && this.getLoggedInFlag()) {
        this.sized = true;
        // Redirect to the main page
        this.$router.push("/main/map");
      }
    } catch (error) {
      console.error("Failed to fetch settings:", error);
      // Handle errors, maybe show an error message to the user
    }
  },
  methods: {
    hashPassword(password) {
      return CryptoJS.SHA256(password).toString();
    },
    async getLoggedInFlag() {
      try {
        const encryptedFlag = await db.Settings.get("Sized");
        if (encryptedFlag) {
          const secretKey = "your-secret-key"; // Replace with your actual key
          const bytes = CryptoJS.AES.decrypt(encryptedFlag, secretKey);
          const decryptedFlag = bytes.toString(CryptoJS.enc.Utf8);
          return stringToBoolean(decryptedFlag); // Convert to boolean
        }
        return ""; // Default if no flag is found
      } catch (error) {
        console.error("Error fetching the Sized flag:", error);
        return ""; // Default if an error occurs
      }
    },
    goToRegister() {
      this.$router.push("main/register"); // Redirect to the register page
    },
    validateEmail() {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.email) {
        this.emailError = "Email address is required";
        return false;
      } else if (!re.test(this.email)) {
        this.emailError = "Please enter a valid email address";
        return false;
      }
      this.emailError = "";
      return true;
    },
    async login() {
      if (!this.validateEmail()) {
        return; // Stop the login process if validation fails
      }

      try {
        // Ensure the database is open before accessing it
        if (!db.isOpen()) {
          await db.open();
        }

        const formData = new FormData();
        formData.append("src", this.email);
        formData.append("pwd", this.password);
        formData.append("utype", "O");

        console.log(this.email);
        console.log(this.password);

        const response = await fetch(
          process.env.VUE_APP_OCEAN_API_BASE_URL + "registerUser",
          {
            method: "POST",
            body: formData,
          }
        );

        const data = await response.json();

        // Handle the API response
        if (data[0] === "W001") {
          // Successful login, store the user data in IndexedDB
          const id = 1; // Fixed ID for the settings entry
          let settings = await db.Settings.get(id);

          const encryptedAuthData = CryptoJS.AES.encrypt(
            JSON.stringify({ password: this.password, timestamp: Date.now() }),
            "the--key!"
          ).toString();

          const encryptedAuthDataLoggedIn = CryptoJS.AES.encrypt(
            "true",
            "the--key!"
          ).toString();

          if (settings) {
            // Update existing settings
            await db.Settings.update(id, {
              Email: this.email,
              Sized: encryptedAuthDataLoggedIn,
              Layout: encryptedAuthData,
              Token: this.key,
            });
          } else {
            // Create new settings if not exist
            await db.Settings.add({
              SettingId: id,
              Email: this.email,
              Sized: encryptedAuthDataLoggedIn,
              Layout: encryptedAuthData,
              Token: this.key,
            });
          }
          this.$router.push("/main/map");
        } else if (data[0] === "W002") {
          // User needs to confirm email, show popup
          alert("Please check your email to confirm your account.");
        } else if (data[0].startsWith("E")) {
          // Handle error cases (wrong credentials)
          this.loginError = "Invalid credentials, please try again.";
          alert(this.loginError);
        }
      } catch (error) {
        console.error("Login failed:", error);
        this.loginError =
          "An error occurred while logging in. Please try again.";
        alert(this.loginError);
      }
    },
  },
};
</script>

<style scoped>
html {
  font-size: 20px !important;
}
.form-control {
  font-size: 16px;
}
.btn {
  border: 1px solid rgb(214, 214, 214);
}
.btn-primary {
}

#emailMessage {
  padding-top: 10px;
}
</style>
